<template lang="html">
    <div class="container-fluid p-5">
        <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        Print View
                    </div>
                    <div class="card-body">
                    <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="false"
                            :preview-modal="true" :paginate-elements-by-height="3400" filename="hee hee"
                            :pdf-quality="2" :manual-pagination="false" pdf-format="a4" pdf-orientation="portrait"
                            pdf-content-width="800px" @progress="onProgress($event)"
                            @hasStartedGeneration="hasStartedGeneration()" @hasGenerated="hasGenerated($event)"
                            ref="html2Pdf">
                            <section slot="pdf-content">
                                <div style="width:100%;">
                                    <div style="width: 50%;float: left;">
                                        <p style="padding-left: 10%;padding-top: 30px;font-size: 46px;font-weight: 900;width: 100%;margin: 0px;">
                                            {{mortgageRequest.pNoDate}} <span style="font-size: 12px">({{new Date().toLocaleTimeString('en-US')}})</span>
                                        </p>
                                    </div>
                                    <div style="width: 50%;float: left;">
                                       <p style="padding-left: 35%;padding-top: 30px;font-size: 36px;font-weight: 900;width: 100%;">
                                            {{mortgageRequest.noDate}}-<span v-if="mortgageRequest.noType != 'No'">{{mortgageRequest.noType}}-</span>{{mortgageRequest.no}}
                                        </p>
                                    </div>
                                </div>
                                <div style="width:100%;">
                                    <p style="padding-left: 25%;font-size: 16px;font-weight: 900;width: 100%;margin-bottom: 0px;">
                                        {{mortgageRequest.type}} {{mortgageRequest.referenceNote}}
                                    </p>
                                </div>
                                <div style="width:100%;float: left;">
                                    <p style="padding-left: 25%;padding-top: 10px;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                        <span v-if="mortgageRequest.mortgageWeight.v1 != null">{{mortgageRequest.mortgageWeight.v1}} ကျပ်</span>
                                        <span v-if="mortgageRequest.mortgageWeight.v2 != null">{{mortgageRequest.mortgageWeight.v2}} ပဲ</span>
                                        <span v-if="mortgageRequest.mortgageWeight.v3 != null">{{mortgageRequest.mortgageWeight.v3}} ရွေး</span>
                                    </p>
                                </div>
                                <div style="width:100%;float: left;">
                                    <div style="width: 50%;float: left;">
                                        <p style="padding-left: 50%;padding-top: 10px;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                            {{mortgageRequest.loan}}
                                        </p>
                                    </div>
                                    <div style="width: 50%;float: left;">
                                        <p style="padding-left: 20%;padding-top: 10px;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                            {{mortgageRequest.loanMmk}}
                                        </p>
                                    </div>
                                </div>
                                <div style="width:100%;float: left;">
                                    <p style="padding-left: 25%;padding-top: 10px;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                        {{mortgageRequest.loanText}} ကျပ်
                                    </p>
                                </div>
                                <div style="width:100%;float: left;">
                                    <div style="width: 50%;float: left;">
                                        <p style="padding-left: 50%;padding-top: 10px;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                            {{mortgageRequest.name}}
                                        </p>
                                    </div>
                                    <div style="width: 50%;float: left;">
                                        <p style="padding-left: 35%;padding-top: 10px;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                            <!-- {{mortgageRequest.nrc}} --> {{mortgageRequest.referenceNote}}
                                        </p>
                                    </div>
                                </div>
                                <div style="width:100%;float: left;">
                                    <p style="padding-left: 25%;padding-top: 10px;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                        {{mortgageRequest.address}}
                                    </p>
                                </div>
                                <div style="width:100%;float: left;">
                                    <div style="width: 60%;float: left;">
                                        <p style="padding-left: 35%;padding-top: 10px;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                            <span style="padding-right:55px;">{{mortgageRequest.day}}</span>
                                            <span  style="padding-right:57px;">{{mortgageRequest.month}}</span>
                                            <span  style="padding-right:25px;">{{mortgageRequest.year}}</span>
                                        </p>
                                    </div>
                                    <div style="width: 40%;float: left;">
                                        <p style="padding-left: 1%;padding-top: 10px;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                            {{mortgageRequest.interestRate}} %
                                        </p>
                                    </div>
                                </div>
                                <div style="width:100%;float: left;">
                                    <p style="padding-left: 30%;padding-top: 10px;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                        {{mortgageRequest.endDate}}
                                    </p>
                                </div>
                                <div style="width:100%;float: left;">
                                    <p style="padding-left: 67%;padding-top: 30px;font-size: 16px;font-weight: bold;width: 100%;margin: 0px;">
                                       {{mortgageRequest.noDate}}-<span v-if="mortgageRequest.noType != 'No'">{{mortgageRequest.noType}}-</span>{{mortgageRequest.no}}
                                    </p>
                                </div>
                                <div style="width:50%;float: left;">
                                    <p style="padding-left: 100%;padding-top: 30px;font-size: 32px;font-weight: bold;width: 100%;margin: 0px;">
                                       {{mortgageRequest.year}}
                                    </p>
                                </div>
                                <div style="width:50%;float: left;">
                                    <p style="padding-left: 38%;padding-top: 30px;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                        <span style="padding-left:10px;">{{mortgageRequest.mortgageWeight.v1}}</span>
                                        <span  style="padding-left:22px;">{{mortgageRequest.mortgageWeight.v2}}</span>
                                        <span  style="padding-left:22px;">{{mortgageRequest.mortgageWeight.v3}}</span>
                                    </p>
                                </div>
                                <div style="width:100%;float: left;">
                                    <p style="padding-left: 69%;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                        <span style="padding-left:10px;">{{mortgageRequest.jewelWeight.v1}}</span>
                                        <span  style="padding-left:22px;">{{mortgageRequest.jewelWeight.v2}}</span>
                                        <span  style="padding-left:22px;">{{mortgageRequest.jewelWeight.v3}}</span>
                                    </p>
                                </div>
                                <div style="width:100%;float: left;">
                                    <p style="padding-left: 69%;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                        <span style="padding-left:10px;">{{mortgageRequest.goldWeight.v1}}</span>
                                        <span  style="padding-left:22px;">{{mortgageRequest.goldWeight.v2}}</span>
                                        <span  style="padding-left:22px;">{{mortgageRequest.goldWeight.v3}}</span>
                                    </p>
                                </div>
                                <!--  -->
                                <p style="visibility: hidden;">loam</p>
                                <p style="visibility: hidden;">loam</p>
                                <!--  -->
                                <div style="width:100%;">
                                        <p style="padding-left: 68%;padding-top: 20px;font-size: 18px;font-weight: bold;font-weight: bold;width: 100%;">
                                            {{mortgageRequest.noDate}}-<span v-if="mortgageRequest.noType != 'No'">{{mortgageRequest.noType}}-</span>{{mortgageRequest.no}}
                                        </p>
                                    </div>
                                <div style="width: 70%; float: left;padding-left: 8%;">
                                    <div style="width:100%;">
                                        <p style="padding-left: 30%;padding-top: 10px;font-size: 16px;font-weight: 900;width: 100%;margin-bottom: 2px;">
                                            {{mortgageRequest.type}} {{mortgageRequest.referenceNote}}
                                        </p>
                                    </div>
                                    <div style="width:100%;float: left;">
                                        <p style="padding-left: 30%;padding-top: 14px;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                            <span v-if="mortgageRequest.mortgageWeight.v1 != null">{{mortgageRequest.mortgageWeight.v1}} ကျပ်</span>
                                            <span v-if="mortgageRequest.mortgageWeight.v2 != null">{{mortgageRequest.mortgageWeight.v2}} ပဲ</span>
                                            <span v-if="mortgageRequest.mortgageWeight.v3 != null">{{mortgageRequest.mortgageWeight.v3}} ရွေး</span>
                                        </p>
                                    </div>
                                    <div style="width:100%;float: left;">
                                        <div style="width: 50%;float: left;">
                                            <p style="padding-left: 60%;padding-top: 14px;font-size: 16px;font-weight: 900;width: 40%;margin: 0px;">
                                                {{mortgageRequest.loan}}
                                            </p>
                                        </div>
                                        <div style="width: 50%;float: left;">
                                            <p style="padding-left: 50%;padding-top: 14px;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                                {{mortgageRequest.loanMmk}}
                                            </p>
                                        </div>
                                    </div>
                                    <div style="width:100%;float: left;">
                                        <p style="padding-left: 30%;padding-top: 14px;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                            {{mortgageRequest.loanText}} ကျပ်
                                        </p>
                                    </div>
                                    <div style="width:100%;float: left;">
                                        <div style="width: 50%;float: left;">
                                            <p style="padding-left: 60%;padding-top: 10px;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                                {{mortgageRequest.name}}
                                            </p>
                                        </div>
                                        <div style="width: 50%;float: left;">
                                            <p style="padding-left: 85%;padding-top: 10px;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                                <!-- {{mortgageRequest.nrc}} --> {{mortgageRequest.referenceNote}}
                                            </p>
                                        </div>
                                    </div>
                                    <div style="width:100%;float: left;">
                                        <p style="padding-left: 30%;padding-top: 10px;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                            {{mortgageRequest.address}}
                                        </p>
                                    </div>
                                    <div style="width:100%;float: left;">
                                        <div style="width: 50%;float: left;">
                                            <p style="padding-left: 50%;padding-top: 10px;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                                <span style="padding-right:45px;">{{mortgageRequest.day}}</span>
                                                <span  style="padding-right:47px;">{{mortgageRequest.month}}</span>
                                                <span  style="padding-right:35px;">{{mortgageRequest.year}}</span>
                                            </p>
                                        </div>
                                        <div style="width: 50%;float: left;">
                                            <p style="padding-left: 80%;padding-top: 10px;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                                {{mortgageRequest.interestRate}} %
                                            </p>
                                        </div>
                                    </div>
                                    <div style="width:100%;float: left;">
                                        <p style="padding-left: 40%;padding-top: 10px;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                            {{mortgageRequest.endDate}} <span style="font-size: 12px">({{new Date().toLocaleTimeString('en-US')}})</span>
                                        </p>
                                    </div>
                                </div>
                                <div style="width:30%;float: left;">
                                    <div style="width:100%;float: left;" >
                                        <p style="visibility: hidden;">loam</p>
                                        <p style="visibility: hidden;">loam</p>
                                        <p style="visibility: hidden;">loam</p>
                                        <p style="visibility: hidden;">loam</p>
                                    </div>
                                    <div style="width:100%;float: left;">
                                        <p style="padding-left: 43%;padding-top: 10px;font-size: 18px;font-weight: bold;width: 100%;margin: 0px;">
                                            {{mortgageRequest.noDate}}-<span v-if="mortgageRequest.noType != 'No'">{{mortgageRequest.noType}}-</span>{{mortgageRequest.no}}
                                        </p>
                                    </div>
                                    <div style="width:100%;float: left;">
                                        <p style="padding-left: 50%;padding-top: 30px;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                            <span  style="padding-left:10px;">{{mortgageRequest.mortgageWeight.v1}}</span>
                                            <span  style="padding-left:15px;">{{mortgageRequest.mortgageWeight.v2}}</span>
                                            <span  style="padding-left:15px;">{{mortgageRequest.mortgageWeight.v3}}</span>
                                        </p>
                                    </div>
                                    <div style="width:100%;float: left;">
                                        <p style="padding-left: 50%;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                            <span  style="padding-left:10px;">{{mortgageRequest.jewelWeight.v1}}</span>
                                            <span  style="padding-left:15px;">{{mortgageRequest.jewelWeight.v2}}</span>
                                            <span  style="padding-left:15px;">{{mortgageRequest.jewelWeight.v3}}</span>
                                        </p>
                                    </div>
                                    <div style="width:100%;float: left;">
                                        <p style="padding-left: 50%;font-size: 16px;font-weight: 900;width: 100%;margin: 0px;">
                                            <span  style="padding-left:10px;">{{mortgageRequest.goldWeight.v1}}</span>
                                            <span  style="padding-left:15px;">{{mortgageRequest.goldWeight.v2}}</span>
                                            <span  style="padding-left:15px;">{{mortgageRequest.goldWeight.v3}}</span>
                                        </p>
                                    </div>
                                </div>
                                <div style="float:left;width:300px;height:100px;margin-top: 30px;margin-left: 50px;font-weight: 900;">
                                    <p style="margin:0px;padding-left:20px;font-size: 18px;">
                                        {{mortgageRequest.noDate}}-<span v-if="mortgageRequest.noType != 'No'">{{mortgageRequest.noType}}-</span>{{mortgageRequest.no}}
                                    </p>
                                    <p style="margin:0px;padding-left:20px;font-size: 18px;" v-if="mortgageRequest.referenceNote">
                                       {{mortgageRequest.referenceNote}}
                                    </p>
                                    <p style="margin:0px; padding-left:20px;font-size: 18px;">
                                        {{mortgageRequest.type}}
                                    </p>
                                    <span style="margin:0px; padding-left:20px;font-size: 12px">({{new Date().toLocaleTimeString('en-US')}})</span>
                                </div>
                                <div style="float:left;width:100px;height:100px;margin-top: 30px;margin-left: 10px;font-weight: 900;" >
                                    <p style="margin:0px;font-size: 18px;">
                                        {{mortgageRequest.loan}}
                                    </p>
                                    <p style="margin:0px;font-size: 18px;">
                                        {{mortgageRequest.name}}
                                    </p>
                                    <p style="margin:0px;font-size: 18px;">
                                        {{weight}}
                                    </p>
                                     <!-- <p style="margin:0px">
                                        {{mortgageRequest.startDate}}
                                    </p>
                                     <p style="margin:0px">
                                        {{mortgageRequest.endDate}}
                                    </p> -->
                                </div>
                                 <div style="float:left;width:100px;height:100px;margin-top: 30px;margin-left: 10px;font-weight: 900;" >
                                    <p style="margin:0px;font-size: 40px;text-align:left;">
                                        <span v-if="mortgageRequest.noType != 'No'">{{mortgageRequest.noType}}-</span>{{mortgageRequest.pNoDate}}
                                    </p>
                                </div>
                                <!-- <div style="float:left;width:100px;height:100px;float:left;margin-top: 20px;font-weight: bold;">
                                   <p style="margin:0px; font-size: 60px;">
                                        {{mortgageRequest.pNoDate}}
                                    </p>
                                </div> -->
                            </section>
                        </vue-html2pdf>


                        <div class="row mt-2">
                            <button class="form-control" style="width: 10%;" @click="generateReport()"> <i
                                    class="fas fa-print"></i> Print</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css'
    import Printd from 'printd'
    import moment from 'moment';
    import VueHtml2pdf from 'vue-html2pdf'
    export default {
        name: 'print',
        components: {
            Loading,
            VueHtml2pdf
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                pWeight: [],
                weight: "",
                mortgageRequest: {
                    id: "",
                    no: "",
                    itemId: "",
                    type: "",
                    mortgageWeight: {
                        v1: "",
                        v2: "",
                        v3: ""
                    },
                    loan: "",
                    loanMmk: "",
                    loanText: "",
                    name: "",
                    nrc: "",
                    address: "",
                    startDate: "",
                    day: "",
                    month: "",
                    year: "",
                    endDate: "",
                    interestType: "percent",
                    interestRate: 3,
                    referenceNote: "",
                    filterType: "",
                    keep: '',
                    loss: '',
                    goldWeight: {
                        v1: "",
                        v2: "",
                        v3: ""
                    },
                    jewelWeight: {
                        v1: "",
                        v2: "",
                        v3: ""
                    },
                    weight: {
                        v1: "",
                        v2: "",
                        v3: ""
                    }
                },
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                mortgageDetailAction: 'mortgageDetailAction',
            }),
            async getDetail() {
                let option = {
                    id: this.$route.query.id
                }
                await this.mortgageDetailAction({
                    ...option
                }).then(res => {
                    this.dataList = res.data.data
                    this.mortgageRequest.id = res.data.data.id
                    this.mortgageRequest.noDate = res.data.data.no_date
                    this.mortgageRequest.noType = res.data.data.no_type
                    this.mortgageRequest.no = res.data.data.no
                    this.mortgageRequest.itemId = res.data.data.item_id
                    this.mortgageRequest.type = res.data.data.type
                    this.mortgageRequest.mortgageWeight.v1 = res.data.data.mortgage_weight.v1
                    this.mortgageRequest.mortgageWeight.v2 = res.data.data.mortgage_weight.v2
                    this.mortgageRequest.mortgageWeight.v3 = res.data.data.mortgage_weight.v3
                    this.mortgageRequest.loan = res.data.data.loan
                    this.mortgageRequest.loanMmk = res.data.data.loan_mmk
                    this.mortgageRequest.loanText = res.data.data.loan_text
                    this.mortgageRequest.name = res.data.data.name
                    this.mortgageRequest.nrc = res.data.data.nrc
                    this.mortgageRequest.address = res.data.data.address
                    this.mortgageRequest.interestType = res.data.data.interest_type
                    this.mortgageRequest.interestRate = res.data.data.interest_rate
                    this.mortgageRequest.filterType = res.data.data.reference_type
                    this.mortgageRequest.keep = res.data.data.keep
                    this.mortgageRequest.loss = res.data.data.loss
                    this.mortgageRequest.startDate = moment(res.data.data.start_date).format('DD-MM-Y')
                    this.mortgageRequest.day = moment(res.data.data.start_date).format('DD')
                    this.mortgageRequest.month = moment(res.data.data.start_date).format('MM')
                    this.mortgageRequest.year = moment(res.data.data.start_date).format('Y')
                    this.mortgageRequest.endDate = moment(res.data.data.end_date).format('DD-MM-Y')
                    this.mortgageRequest.goldWeight.v1 = res.data.data.gold.v1
                    this.mortgageRequest.goldWeight.v2 = res.data.data.gold.v2
                    this.mortgageRequest.goldWeight.v3 = res.data.data.gold.v3
                    this.mortgageRequest.jewelWeight.v1 = res.data.data.jewel.v1
                    this.mortgageRequest.jewelWeight.v2 = res.data.data.jewel.v2
                    this.mortgageRequest.jewelWeight.v3 = res.data.data.jewel.v3
                    this.mortgageRequest.weight.v1 = res.data.data.weight.v1
                    this.mortgageRequest.weight.v2 = res.data.data.weight.v2
                    this.mortgageRequest.weight.v3 = res.data.data.weight.v3
                    this.mortgageRequest.pNoDate = res.data.data.p_no_date
                    if(res.data.data.mortgage_weight.v1 != null){
                        let v1 = res.data.data.mortgage_weight.v1+"ကျပ်"
                        this.pWeight.push(v1)
                    }
                    if(res.data.data.mortgage_weight.v2 != null){
                        let v2 = res.data.data.mortgage_weight.v2+"ပဲ"
                        this.pWeight.push(v2)
                    }
                    if(res.data.data.mortgage_weight.v3 != null){
                        let v3 = res.data.data.mortgage_weight.v3+"ရွေး"
                        this.pWeight.push(v3)
                    }
                   this.weight = this.pWeight.join('')
                   console.log(this.weight)
                    let month = moment(res.data.data.start_date).format('M')
                    if (this.mortgageRequest.filterType == 'type2') {
                        this.mortgageRequest.referenceNote = "("+res.data.data.reference_loan+")"+res.data.data.reference_note
                    }
                    if (this.mortgageRequest.filterType == 'type3') {
                        this.mortgageRequest.referenceNote = "("+res.data.data.reference_loan+")"+res.data.data.reference_note
                    }
                    if (this.mortgageRequest.filterType == 'type4') {
                        this.mortgageRequest.referenceNote = "("+res.data.data.reference_loan+")"+res.data.data.reference_note
                    }
                    this.isLoading = false
                }).catch(err => this.isLoading = true)
            },
            print() {
                const d = new Printd()
                d.print(document.getElementById('myelement'))
            },
            generateReport() {
                this.$refs.html2Pdf.generatePdf()
            }

        },
        async mounted() {
            this.getDetail()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }
</style>